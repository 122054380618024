import "../styles/globals.css";
import "../styles/newStyle.css";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import Layout from "../components/layout/layout-main/layout";
import TagManager from "react-gtm-module";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import 'animate.css';
import { isMobile } from "react-device-detect";

// import * as ga from '../lib/ga'
const tagManagerArgs = {
  gtmId: "GTM-TJTGF26",
};


function MyApp({ Component, pageProps }) {

  const router = useRouter();
  const [count, setCount] = useState();
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    if (isMobile === true) {
      FreshworksWidget("destroy");
    }

    const localItems = [
      "selected-location",
      "membership",
      "insurance-val",
      "patientAddress",
    ];

    localItems.forEach((element) => {
      window.localStorage.removeItem(element);
    });

    if (router.asPath === "/checkout") {
      if (Cookies.get("cart") == undefined) {
        router.push("/")
      }
    }

    TagManager.initialize(tagManagerArgs);

    setMounted(true)
  }, []);

  return mounted && (
    <Layout count={count}>
      <Component {...pageProps} setCount={setCount} />
    </Layout>
  )

}

export default MyApp;
