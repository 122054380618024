var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c6ed89d4da1eee017ba66f180ad10071f61d7aa0"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://8b7c598d66354c3b82ce6520342c3372@o4504832874184704.ingest.sentry.io/4505071867592704",
  ignoreErrors: [
    /Network Error/i,
    /Request Failed/i,
    /Load failed/i,
    /failed to load/i,
    /failed to fetch/i,
  ],
});
