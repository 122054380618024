import Navbar from "../navbar/navbar";
import DarkFooter from "../footer/darkFooter";
import { useRouter } from "next/router";
import React from "react";
import { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import Cookies, { set } from "js-cookie";
import { useScrollDirection } from "react-use-scroll-direction";
import Link from 'next/link'
import Sidebar from "../../AntDesign/Sidebar";
const Layout = ({ children }) => {
  const [insurance, setInsurance] = useState(true);
  const [show, setShow] = useState(true)
  const { isScrolling } = useScrollDirection();
  const [scrollStart, setScrollStart] = useState(false);

  useEffect(() => {
    if (isScrolling) {
      setScrollStart(true);
    }
  }, [isScrolling]);

  const setInsuranceVal = (val) => {
    setInsurance(val);
    Cookies.remove("totalPrice");
    Cookies.remove("cart");
    Cookies.remove("discount");
    Cookies.remove("mainPrice");
  };
  const getTestName = (param) => {
    switch (param) {
      case "/allergies":
        return "Allergy Tests";
      case "/womenhealth":
        return "Womens Health Tests";
      case "/menhealth":
        return "Mens Health Tests";
      case "/std":
        return "Sexual Health Tests";
      case "/covid":
        return "Covid Tests";
      case "/thyroid":
        return "General Wellness Tests";
      default:
        return "All Tests";
    }
  };

  const getTestPrice = (param) => {
    switch (param) {
      case "/allergies":
        return "From $99.00";
      case "/womenhealth":
        return "From $99.00";
      case "/menhealth":
        return "From $99.00";
      case "/std":
        return "From $129.00";
      case "/covid":
        return "From $149.00";
      case "/thyroid":
        return "From $49.00";
      default:
        return "From $99.00";
    }
  };

  const router = useRouter();
  return (
    <>
      <ChatBotComponent />

      {router.pathname == "/visit1" ||
        router.pathname == "/messages" ||
        router.pathname == "/visits" ||
        router.pathname == "/information" ||
        router.pathname == "/messages" ||
        router.pathname == "/messages/patient"

        ? <div
          className={"shadow_container"
          }><Sidebar> {children}</Sidebar></div> :
        <>
          {router.pathname === "/program-finder" ? (
            <div className="program"></div>
          ) : (
            <></>
          )}
          <div
            className={
              router.pathname === "/program-finder"
                ? "shadow_container program-finder"
                : "shadow_container"
            }
          >
            {router.pathname == "/login" ||
              router.pathname == "/checkout" ||
              router.pathname == "/visits" ||
              router.pathname == "/subscriptions/[id]" ||
              router.pathname == "/results/[slug]" ||
              router.pathname == "/start" ||
              router.pathname == "/forgotpassword" ||
              router.pathname == "/followup" ||
              router.pathname == "/createaccount" ||
              router.pathname == "/profile" ||

              router.pathname == "/welcome" ||
              router.pathname == "/patient-visits" ||
              router.pathname == "/acne_checkin" ||
              router.pathname == "/patient-messages" ||
              router.pathname == "/refer" ||
              router.pathname == "/get-started" ||
              // router.pathname == "/wl_checkout" ||
              router.pathname == "/update-payment" ||
              router.pathname == "/checkin" ||
              // router.pathname == "/success" ||
              router.pathname == "/patient_results" ||
              router.pathname == "/coach_chat" ||
              router.pathname == "/intake-form" ||
              router.pathname == "/patient-info" ||
              router.pathname == "/quiz" ||
              router.pathname == "/membership" ||
              router.pathname == "/refund" ||
              router.pathname === "/appointment" ||
              router.pathname == "/feedback" ? (
              <>{children}</>
            ) : (
              <>
                <Navbar insurance={insurance} setInsuranceVal={setInsuranceVal} />
                {React.cloneElement(children, {
                  insurance: insurance,
                  setInsuranceVal: setInsuranceVal,
                })}
                {router.pathname == "/program-finder" ? "" : <DarkFooter />}
              </>
            )}
          </div>

          {show && (
            <CookieConsent
              location="bottom"
              buttonText="Accept cookies"
              cookieName="next_medical"
              style={{ background: "#2B373B", zIndex: "99999999999" }}
              buttonStyle={{
                color: "#fff",
                backgroundColor: "#368fff",
                transition: "all 0.5s",
                boxShadow: "0 3px 4px 0 rgb(29 92 185 / 5%)",
                padding: "8px 22px",
                borderRadius: "5px",
                fontWeight: "500",
              }}
              expires={150}
            >
              <span>
                We use cookies, analytics tools and similar technologies to ensure you get the best experience on our website. <span className="span-banner">To manage your cookies, please click<Link href="http://nextmed.com/privacy_policy#cookies"><a target="_blank" className="privay-cookie-btn" > Cookies Settings</a></Link></span>
              </span>
            </CookieConsent>)}
        </>}

    </>
  );
};
export default Layout;

export const ChatBotComponent = () => {
  useEffect(() => {
    // Initialize the ChatBot widget after the script is loaded
    window.ChatBot?.Widget({ id: '0975dbcc-d2a5-4aaf-8e46-c020ae625652' });
  }, []);

  return (
    <div>
      {/* Your component's JSX */}
    </div>
  );
}